<script>
import Vue from "vue";
import GlobalVue from "@helper/Global.vue";

Vue.component("Header", () => import("@frontend/part/Header.vue"));
Vue.component("Footer", () => import("@frontend/part/Footer.vue"));
Vue.component("VModal", () => import("@frontend/components/VModal.vue"));

export default {
  extends: GlobalVue,
  name: "FoLayout",
  mounted() {
    this.$set(this.$root, "base", this);
  },
};
</script>

<template>
  <div id="wrapper" class="clearfix">
    <!-- <section id=""> -->

    <Header v-show="!$route.meta.noNavigation"></Header>
    
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <!-- </section> -->
    <Footer></Footer>
    <div id="gotoTop" class="icon-angle-up"></div>
    <ImageFilter></ImageFilter>
    <!-- <VAlert></VAlert> -->
    <VModal></VModal>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
